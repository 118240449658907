<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <el-row :gutter="20">
        <el-col :span="5">
          <div class="demo-input-suffix">
            <el-input
              placeholder="请输入需要查询的药品名称"
              v-model="searchdrug"
              @input="searchwesternmedicineInput"
              @blur="searchwesternmedicineblur"
            >
              <template slot="prepend">药品名称</template>
            </el-input>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="demo-input-suffix">
            <el-input
              placeholder="查询多少天内到期的药品"
              v-model.number="searchdate"
            >
              <template slot="prepend">药品有效期</template>
            </el-input>
          </div>
        </el-col>
        <el-col :span="3">
          <div class="without-outline">
            <el-button type="info" @click="resetting()">重置</el-button>
            <el-button type="primary" @click="searchExpirationDatemedicine()"
              >查询</el-button
            >
          </div>
        </el-col>
        <el-col :span="11">
          <div class="without-outline flex aligncenter">
            入库日期范围查询：
            <el-date-picker
              v-model="starttoend"
              type="daterange"
              value-format="timestamp"
              range-separator="至"
              :default-time="['00:00:00', '23:59:59']"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
            <el-button class="mr20" type="info" @click="resetting()"
              >重置</el-button
            >
            <el-button class="mr20" type="primary" @click="timeframe()"
              >查询</el-button
            >
          </div>
        </el-col>
      </el-row>
    </div>
    <!--添加-->
    <el-table
      highlight-current-row
      :cell-style="{ padding: '0px', 'text-align': 'center' }"
      class="drugdata"
      style="width: 100%; margin-bottom: 20px; font-size: 12px"
      :row-style="{ height: '45px' }"
      :header-cell-style="{ background: '#f5f5f5', 'text-align': 'center' }"
      :data="items"
      row-key="_id"
      border
    >
      <el-table-column type="selection" width="55" height="50">
      </el-table-column>
      <el-table-column
        prop="fullName"
        fixed="left"
        show-overflow-tooltip
        label="药品名称"
        width="180"
      >
      </el-table-column>
      <el-table-column
        prop="manufacturer"
        show-overflow-tooltip
        label="生产厂商"
        width="180"
      ></el-table-column>
      <el-table-column prop="inventory" label="库存数量" width="100">
        <template slot-scope="scope">
          {{ scope.row.inventory }}{{ scope.row.packageUnit
          }}<span v-if="scope.row.dismounting === 1"
            >{{ scope.row.split }}{{ scope.row.pieceUnit }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="packageUnit"
        label="库存单位"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="displayspec"
        label="规格"
        width="140"
      ></el-table-column>
      <el-table-column prop="packageprice" label="采购价格" width="100">
      </el-table-column>
      <el-table-column prop="sellprice" label="销售价格" width="110">
      </el-table-column>
      <el-table-column
        prop="inventorynumber"
        show-overflow-tooltip
        label="入库批号"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="GenerationDate"
        label="生产日期"
        :formatter="formatTime"
      ></el-table-column>
      <el-table-column
        prop="ExpirationDate"
        label="过期日期"
        :formatter="formatTime"
      >
      </el-table-column>
      <el-table-column
        prop="updatedAt"
        label="入库时间"
        :formatter="formatTime"
      >
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="150">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleedit(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      @current-change="handlePageChange"
      @size-change="handlePageSizeChange"
      :current-page="currentPage"
      :page-sizes="[20, 30, 40, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-dialog
      title="入库信息"
      :visible.sync="dialogedit"
      :close-on-click-modal="false"
    >
      <el-form
        :model="drugdataForm"
        :rules="drugdatarules"
        ref="drugdataForm"
        label-width="100px"
        class="goodsForm"
      >
        <el-form-item label="商品类型" prop="type">
          <el-select v-model="drugdataForm.type" :clearable="false" placeholder="商品类型">
            <el-option
              v-for="item in goodstypeoptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="能否拆分" prop="dismounting">
          <el-select v-model="drugdataForm.dismounting" :clearable="false" placeholder="请选择">
            <el-option
              v-for="item in dismountingoptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品名称" prop="fullName">
          <el-input v-model="drugdataForm.fullName"></el-input>
        </el-form-item>
        <el-form-item label="生产厂家" prop="manufacturer">
          <el-input v-model="drugdataForm.manufacturer"></el-input>
        </el-form-item>
        <el-form-item
          label="供应商"
          v-show="drugdataForm.type !== 4 && drugdataForm.type !== 5"
          prop="supplier"
        >
          <el-select
            v-model="drugdataForm.supplier"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in supplieroptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="首级规格"
          v-show="
            drugdataForm.type !== 2 &&
            drugdataForm.type !== 4 &&
            drugdataForm.type !== 5
          "
          prop="medicineDosageNum"
        >
          <el-tooltip class="item" effect="dark" placement="top-start">
            <div slot="content">
              如商品规格为"100ml*30只/盒"<br />则填入"100"没有就填0
            </div>
            <el-input-number
              v-model="drugdataForm.medicineDosageNum"
              controls-position="right"
            ></el-input-number>
          </el-tooltip>
        </el-form-item>
        <el-form-item
          label="首级单位"
          v-show="
            drugdataForm.type !== 2 &&
            drugdataForm.type !== 4 &&
            drugdataForm.type !== 5
          "
          prop="medicinedosageunit"
        >
          <el-tooltip class="item" effect="dark" placement="top-start">
            <div slot="content">
              如商品规格为"100ml*30只/盒"<br />则填入"ml"没有可不选
            </div>
            <el-select
              v-model="drugdataForm.medicineDosageUnit"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in units"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-tooltip>
        </el-form-item>
        <el-form-item
          label="次级规格"
          v-show="
            drugdataForm.type !== 2 &&
            drugdataForm.type !== 4 &&
            drugdataForm.type !== 5
          "
          prop="pieceNum"
        >
          <el-tooltip class="item" effect="dark" placement="top-start">
            <div slot="content">
              如商品规格为"100ml*30只/盒"<br />则填入"30"没有就填0
            </div>
            <el-input-number
              v-model="drugdataForm.pieceNum"
              controls-position="right"
            ></el-input-number>
          </el-tooltip>
        </el-form-item>
        <el-form-item
          label="次级单位"
          v-show="
            drugdataForm.type !== 2 &&
            drugdataForm.type !== 4 &&
            drugdataForm.type !== 5
          "
          prop="pieceunit"
        >
          <el-tooltip class="item" effect="dark" placement="top-start">
            <div slot="content">
              如商品规格为"100ml*30只/盒"<br />则填入"只"没有可不选
            </div>
            <el-select
              v-model="drugdataForm.pieceUnit"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in units"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="整体规格" prop="displayspec">
          <el-input v-model="drugdataForm.displayspec"></el-input>
        </el-form-item>
        <el-form-item label="采购单位" prop="packageUnit">
          <el-tooltip class="item" effect="dark" placement="top-start">
            <div slot="content">
              如商品规格为"100ml*30只/盒"<br />则填入"盒"
            </div>
            <el-select
              v-model="drugdataForm.packageUnit"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in units"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="采购数量" prop="procurenum">
          <el-tooltip class="item" effect="dark" placement="top-start">
            <div slot="content">输入该商品的采购数量<br /></div>
            <el-input-number
              :precision="0"
              v-model.number="drugdataForm.procurenum"
              controls-position="right"
            ></el-input-number>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="采购单价" prop="packageprice">
          <el-tooltip class="item" effect="dark" placement="top-start">
            <div slot="content">输入该商品的采购单价<br /></div>
            <el-input-number
              v-model="drugdataForm.packageprice"
              controls-position="right"
            ></el-input-number>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="销售单价" prop="sellprice">
          <el-tooltip class="item" effect="dark" placement="top-start">
            <div slot="content">输入该商品的销售单价<br /></div>
            <el-input-number
              v-model="drugdataForm.sellprice"
              controls-position="right"
            ></el-input-number>
          </el-tooltip>
        </el-form-item>
        <el-form-item
          v-if="drugdataForm.dismounting === 1"
          label="拆分单价"
          prop="pieceprice"
        >
          <el-tooltip class="item" effect="dark" placement="top-start">
            <div slot="content">
              该单价为可拆分的单品价格如商品规格为"100ml*30只/盒，一盒30元<br />那么单价为30/30只，1元
            </div>
            <el-input-number
              :value="drugpieceprice"
              controls-position="right"
            ></el-input-number>
          </el-tooltip>
        </el-form-item>
        <el-form-item
          label="生产日期"
          prop="GenerationDate"
          class="GenerationDate"
        >
          <el-date-picker
            v-model="drugdataForm.GenerationDate"
            type="date"
            :clearable="false"
            value-format="timestamp"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="过期日期"
          prop="ExpirationDate"
          class="ExpirationDate"
        >
          <el-date-picker
            v-model="drugdataForm.ExpirationDate"
            value-format="timestamp"
            type="date"
            :clearable="false"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="国药准字"
          v-show="drugdataForm.type === 2"
          prop="medicinenmpn"
        >
          <el-input v-model="drugdataForm.medicinenmpn"></el-input>
        </el-form-item>
        <el-form-item
          label="用药方式"
          v-show="
            drugdataForm.type !== 2 &&
            drugdataForm.type !== 4 &&
            drugdataForm.type !== 5
          "
          prop="usage"
        >
          <el-select
            v-model="drugdataForm.usage"
            clearable
            allow-create
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in usageoptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="用药频率"
          v-show="
            drugdataForm.type !== 2 &&
            drugdataForm.type !== 4 &&
            drugdataForm.type !== 5
          "
          prop="freq"
        >
          <el-select
            v-model="drugdataForm.freq"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in freqselect"
              :key="item.name"
              :label="item.frequency"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="默认数量"
          v-show="
            drugdataForm.type !== 2 &&
            drugdataForm.type !== 4 &&
            drugdataForm.type !== 5
          "
          prop="dosage"
        >
          <el-input v-model.number="drugdataForm.dosage"></el-input>
        </el-form-item>
        <el-form-item
          label="默认单位"
          v-if="drugdataForm.medicineDosageUnit && drugdataForm.pieceUnit"
          prop="dosageUnit"
        >
          <el-select
            v-model="drugdataForm.dosageUnit"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              :label="drugdataForm.medicineDosageUnit"
              :value="drugdataForm.medicineDosageUnit"
            >
            </el-option>
            <el-option
              :label="drugdataForm.pieceUnit"
              :value="drugdataForm.pieceUnit"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clocedialog()">取 消</el-button>
        <el-button type="primary" @click="editgood()">修 改</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>
<script>
import unitsData from "@/utils/unit";
import excel from "@/utils/excel";
import XLSX from "xlsx";
export default {
  data() {
    return {
      dialogTableVisible: false,
      editdialogFormVisible: false,
      dialogFormVisible: false,
      dialogedit: false,
      drugdataForm: {},
      formLabelWidth: "120px",
      currentPage: 1, // 当前页码
      pageSize: 20, // 每页数据量
      total: 0, // 总数据量
      items: [],
      timeslot: "",
      search: "",
      searchdrug: "",
      searchdate: "",
      units: unitsData,
      searchsupplier: "",
      starttoend: [],
      drugdatarules: {
        type: [
          { required: true, message: "请选择商品类型", trigger: "change" },
        ],
        dismounting: [
          { required: true, message: "请选择是否可以拆分", trigger: "change" },
        ],
        fullName: [
          { required: true, message: "请输入商品名称", trigger: "blur" },
        ],
        packageUnit: [
          {
            required: true,
            message: "请选择单位，或者输入",
            trigger: "change",
          },
        ],
        packageprice: [
          {
            type: "number",
            required: true,
            message: "请输入价格",
            trigger: "blur",
          },
        ],
        procurenum: [
          {
            type: "number",
            required: true,
            message: "请输入采购数量",
            trigger: "blur",
          },
        ],
        sellprice: [
          {
            type: "number",
            required: true,
            message: "请输入销售单价",
            trigger: "blur",
          },
        ],
      },
      goodstypeoptions: [
        {
          value: 1,
          label: "中西成药",
        },
        {
          value: 2,
          label: "中药",
        },
        {
          value: 3,
          label: "商品",
        },
        {
          value: 6,
          label: "物资",
        },
        {
          value: 7,
          label: "其它",
        },
      ],
      dismountingoptions: [
        {
          value: 0,
          label: "不能",
        },
        {
          value: 1,
          label: "能",
        },
      ],
      supplieroptions: [],
      usageoptions: [
        { value: "口服", label: "口服" },
        { value: "含服", label: "含服" },
        { value: "嚼服", label: "嚼服" },
        { value: "晨服", label: "晨服" },
        { value: "餐前服", label: "餐前服" },
        { value: "餐中服", label: "餐中服" },
        { value: "餐后服", label: "餐后服" },
        { value: "睡前服", label: "睡前服" },
        { value: "静脉滴注", label: "静脉滴注" },
        { value: "静脉注射", label: "静脉注射" },
        { value: "肌内注射", label: "肌内注射" },
        { value: "腔内注射", label: "腔内注射" },
        { value: "雾化吸入", label: "雾化吸入" },
        { value: "皮下注射", label: "皮下注射" },
        { value: "皮内注射", label: "皮内注射" },
        { value: "穴位注射", label: "穴位注射" },
        { value: "直肠滴注", label: "直肠滴注" },
        { value: "局部注射", label: "局部注射" },
        { value: "局部麻醉", label: "局部麻醉" },
        { value: "超声透药", label: "超声透药" },
        { value: "溶媒用", label: "溶媒用" },
        { value: "外用", label: "外用" },
        { value: "滴眼", label: "滴眼" },
        { value: "滴鼻", label: "滴鼻" },
        { value: "滴耳", label: "滴耳" },
        { value: "口腔喷入", label: "口腔喷入" },
        { value: "鼻腔喷入", label: "鼻腔喷入" },
        { value: "含漱", label: "含漱" },
        { value: "涂抹", label: "涂抹" },
        { value: "塞肛", label: "塞肛" },
        { value: "直肠给药", label: "直肠给药" },
        { value: "阴道给药", label: "阴道给药" },
      ],
      freqselect: [
        { frequency: "每天1次", multiplier: 1, name: "qd" },
        { frequency: "每天2次", multiplier: 2, name: "bid" },
        { frequency: "每天3次", multiplier: 3, name: "tid" },
        { frequency: "每天4次", multiplier: 4, name: "qid" },
        { frequency: "隔日1次", multiplier: 0.5, name: "qod" },
        { frequency: "每周1次", multiplier: 1 / 7, name: "qw" },
        { frequency: "每周2次", multiplier: 2 / 7, name: "biw" },
        { frequency: "每2小时1次", multiplier: 12, name: "q2h" },
        { frequency: "每4小时1次", multiplier: 6, name: "q4h" },
        { frequency: "每6小时1次", multiplier: 4, name: "q6h" },
        { frequency: "每8小时1次", multiplier: 3, name: "q8h" },
        { frequency: "每12小时1次", multiplier: 2, name: "q12h" },
      ],
      form: {
        _id: "",
        name: "",
        manufacturer: "",
        drugtype: "",
        stock: "",
        specification: "",
        unit: "",
        productionDate: "",
        expirationDate: "",
        approvalNumber: "",
        supplier: "",
        purchasePrice: "",
        salePrice: "",
        inventorynumber: "",
        inventoryDate: "",
      },
      editform: {
        _id: "",
        name: "",
        rolename: "",
        des: "",
      },
      checkoptions: [],
      tableColumns: [], // 表格表头数据
      options: [
        {
          value: "处方",
          label: "处方",
        },
        {
          value: "非处方",
          label: "非处方",
        },
      ],
      //所有键的值
      columnHeader: [],

      // 导入的excel的数据
      excelData: [],

      // 表格显示列
      checkboxTableColumn: [],

      //表格数据
      tableData: [],

      // 当前分页
      upcurrentPage: 1,

      // 每页显示数量
      uppageSize: 10,

      // 数据总数
      uptotal: 0,
    };
  },
  computed: {},
  methods: {
    editgood() {
      this.$api.goods.editgood(this.drugdataForm).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.dialogedit = false;
          this.loadItems();
        } else {
          this.$message({
            message: "修改失败，检查输入的字段",
            type: "warning",
          });
        }
      });
    },
    clocedialog() {
      this.dialogedit = false;
      this.drugdataForm = {};
    },
    getallsupplier() {
      this.$api.inventory.allsupplier().then((res) => {
        this.supplieroptions = res.data.data.map((item) => {
          return {
            label: item.suppliername,
            value: item._id,
          };
        });
      });
    },
    timeframe() {
      console.log(this.starttoend);
      (this.currentPage = 1),
        (this.pageSize = 20),
        this.debounce(this.searchstarttoendmedicine, 1000);
    },
    debounce(func, delay) {
      clearTimeout(this.timer);
      this.timer = setTimeout(func, delay);
    },
    //防抖
    searchwesternmedicineInput() {
      (this.currentPage = 1),
        (this.pageSize = 20),
        this.debounce(this.searchwesternmedicine, 1000);
    },
    searchwesternmedicineblur() {
      if (this.searchdrug === "") {
        this.loadItems();
      }
    },
    searchExpirationDatemedicine() {
      if (!this.searchdate) {
        return this.$message({
          message: "请输入需要查询的天数",
          type: "warning",
        });
      }
      const data = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        searchdate: this.searchdate,
      };
      this.$api.goods.searchExpirationDatemedicine(data).then((res) => {
        if (res.data.code === 200) {
          this.currentPage = res.data.pagination.currentPage;
          this.pageSize = res.data.pagination.pageSize;
          this.total = res.data.pagination.total;
          this.items = res.data.data;
        } else {
          this.items = [];
        }
      });
    },
    searchstarttoendmedicine() {
      if (this.starttoend.length <= 1) {
        return this.$message({
          message: "请输入需要查询的日期范围",
          type: "warning",
        });
      }
      const data = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        starttoend: this.starttoend,
      };
      this.$api.goods.searchstarttoendmedicine(data).then((res) => {
        if (res.data.code === 200) {
          this.currentPage = res.data.pagination.currentPage;
          this.pageSize = res.data.pagination.pageSize;
          this.total = res.data.pagination.total;
          this.items = res.data.data;
        } else {
          this.items = [];
        }
      });
    },
    searchwesternmedicine() {
      const data = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        search: this.searchdrug,
      };
      this.$api.goods.searchwesternmedicine(data).then((res) => {
        if (res.data.code === 200) {
          this.currentPage = res.data.pagination.currentPage;
          this.pageSize = res.data.pagination.pageSize;
          this.total = res.data.pagination.total;
          this.items = res.data.data;
        } else {
          this.items = [];
        }
      });
    },
    btnClick() {
      document.querySelector(".input-file").click();
    },
    resetting() {
      this.searchdate = "";
      this.loadItems();
    },
    handleedit(data) {
      this.dialogedit = true;
      this.drugdataForm = data;
    },
    handleDelete(data) {
      this.$api.goods.deletegoods(data).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.loadItems();
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.loadItems();
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.loadItems();
    },
    loadItems() {
      const data = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
      };
      this.$api.goods.allwesternmedicine(data).then((res) => {
        if (res.data.code === 200) {
          this.currentPage = res.data.pagination.currentPage;
          this.pageSize = res.data.pagination.pageSize;
          this.total = res.data.pagination.total;
          this.items = res.data.data;
        } else {
          this.items = [];
        }
      });
    },
    formatTime(row, column) {
      let data = row[column.property];
      let dtime = new Date(data);
      const year = dtime.getFullYear();
      let month = dtime.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      let day = dtime.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      let hour = dtime.getHours();
      if (hour < 10) {
        hour = "0" + hour;
      }
      let minute = dtime.getMinutes();
      if (minute < 10) {
        minute = "0" + minute;
      }
      let second = dtime.getSeconds();
      if (second < 10) {
        second = "0" + second;
      }
      return year + "-" + month + "-" + day;
    },
  },
  created() {
    this.loadItems();
    this.getallsupplier();
  },
};
</script>
<style>
.ExpirationDate .el-input__inner {
  width: 80%;
}
.GenerationDate .el-input__inner {
  width: 80%;
}
.goodsForm {
  display: flex;
  flex-wrap: wrap;
}
.goodsForm .el-form-item {
  width: 30%;
}
.without-outline .el-date-editor.el-input__inner {
  width: 50%;
}
.without-outline .el-input__inner {
  width: 380px;
}
.drugdata .el-table__body-wrapper {
  min-height: 550px;
}
.w250 {
  width: 250px;
}
.w120 {
  width: 120px;
}
.flex {
  display: flex;
}
.justifyend {
  justify-content: end;
}
.aligncenter {
  align-items: center;
}
button:focus {
  outline: none;
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 100%;
}
.mr20 {
  margin-left: 20px;
}
</style>
